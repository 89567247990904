<template>
  <loading
    :active="isLoadingModel"
    :can-cancel="true"
    
    color="#ffc107"
    :is-full-page="fullPage"
  ></loading>
 
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-2">
              <Button
                label="New"
                icon="pi pi-plus"
                class="p-button-success mr-2"
                @click="openNew"
              />
              <!-- <Button
                label="Delete"
                icon="pi pi-trash"
                class="p-button-danger"
                @click="confirmDeleteSelected"
                :disabled="!selectedProducts || !selectedProducts.length"
              /> -->
              <!-- &nbsp;
              <Button
                icon="pi pi-sync"
                class="p-button-info mr-2"
                @click="get_list"
              /> -->
            </div>
          </template>

          <template v-slot:end>
            <!-- <FileUpload
              mode="basic"
              accept="application/pdf,image/jpeg,image/png"
              :maxFileSize="1000000"
              label="Import"
              chooseLabel="Import"
              class="mr-2 inline-block"
            /> -->
            <Button
              label="Export"
              icon="pi pi-upload"
              class="p-button-help"
              @click="exportCSV($event)"
            />
          </template>
        </Toolbar>

        <DataTable
          :loading="loading"
          ref="dt"
          :lazy="true"
          :totalRecords="totalRecords"
          :paginator="true"
          :value="products"
          v-model:selection="selectedProducts"
          :dataKey="columns[0]"
          :rows="limit"
          :filters="filters"
          @page="onPage($event)"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25, 20]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          responsiveLayout="scroll"
          style="font-size: 12px"
           :rowHover="true" showGridlines
        >
        <template #empty>
        <b style="text-align:center">No records found.</b>
      </template>
          <template #header>
            <div
              class="
                flex flex-column
                md:flex-row md:justify-content-between md:align-items-center
              "
            >
              <h5 class="m-0"><b>Manage Assistant Teacher Master</b></h5>
              <Button icon="pi pi-refresh" @click="get_list()" />
              <!-- <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Search..." />
                            </span> -->
            </div>
          </template>

          <!-- <Column v-for="(col,index) in columns" :field="col" :header="col.toUpperCase()" :key="index"></Column> -->
          <!-- <Column selectionMode="multiple" headerStyle="width: 3rem"></Column> -->

          <Column header="Status" style="min-width: 8rem;text-align: center;" :field="columns[16]">
            <template #body="{ data }">
              <span v-if="data.status=='Active' ||data.status=='active'"><badge :value=data.status severity="success"></badge></span>
            <span v-else-if="data.status=='InActive' ||data.status=='In Active'"><badge :value=data.status severity="warning"></badge></span>
            <span v-else-if="data.status=='Delete' ||data.status=='Deleted'"><badge :value=data.status severity="danger"></badge></span>
            <span v-else>-</span>
            </template>
          </Column>
          <Column header="Sr No" style="min-width: 5rem;text-align: center;">
            <template #body="{ index}">
            <span v-if="page_no==1">{{ +index+ +1 }}</span>
              <span v-else>{{(+index+1)+limit*(page_no-1)}}</span>
            </template>
          </Column>
          <Column header="Name" style="min-width: 3rem" :field="columns[1]">
            <template #body="{ data }">
              {{ data.firsT_NAME }}{{data.middeL_NAME }}{{data.surname}}
            </template>
          </Column>
          <Column header="Course" style="min-width: 10rem" :field="columns[14]">
            <template #body="{ data }">
              <span v-if="data.batchname=='' || data.batchname==null || data.batchname==undefined">-</span>
              <span v-else>{{data.batchname[0]}}</span>
            </template>
          </Column>
          <Column header="Subject" :field="columns[25]">
            <template #body="{ data }">
              <span v-if="data.subjectname=='' || data.subjectname==null || data.subjectname==undefined">-</span>
              <span v-else>{{data.subjectname[0].subjectname}}</span>
            </template>
          </Column>
          <Column header="Adhar No." :field="columns[15]"  style="text-align:right;">
            <template #body="{ data }">
              {{ data.aadhaR_CARD }}
            </template>
          </Column>
          <Column header="City" :field="columns[8]">
            <template #body="{ data }">
              {{ data.citY_VILLAGE }}
            </template>
          </Column>
          <Column header="Mobile" :field="columns[9]"  style="text-align:right;">
            <template #body="{ data }">
              {{ data.mobile }}
            </template>
          </Column>
          <Column header="Email" :field="columns[10]">
            <template #body="{ data }">
              {{ data.email }}
            </template>
          </Column>
          <Column header="Password">
            <template #body="slotProps">
              <Button
                class="p-button-rounded p-button-success p-button-outlined"
                @click="update_pass(slotProps.data)"
                >Update</Button
              >
            </template>
          </Column>
         
          

          
          <Column headerStyle="min-width:10rem;">
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success mr-2"
                @click="editProduct(slotProps.data)"
              />
               <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-warning mt-2"
                @click="confirmDeleteProduct(slotProps.data)"
              /> 
            </template>
          </Column>
        </DataTable>

         <Sidebar
          v-model:visible="visibleFull"
          :baseZIndex="1000"
          position="full"
        >
          <div class="col-12">
            <div class="card">
              <h5>Register Teacher Form</h5>
              <hr />
              <div class="p-fluid formgrid grid">
                <div class="field col-12 md:col-2">
                  <label for="status">Status</label>
                  <Dropdown
                    id="status"
                    v-model="selectstatus"
                    :options="dropdownItems"
                    :value="dropdownItems.value"
                    optionLabel="name"
                    placeholder="Select One"
                    :class="{ 'p-invalid': submitted && !selectstatus }"
                    required="true"
                  ></Dropdown>
                </div>
                <div class="field col-12 md:col-2">
                  <label for="firsT_NAME">First Name</label>
                  <InputText
                    id="firsT_NAME"
                    type="text"
                    v-model.trim="product.firsT_NAME"
                    required="true"
                    autofocus
                    :class="{ 'p-invalid': submitted && !product.firsT_NAME }"
                  />
                </div>
                <div class="field col-12 md:col-2">
                  <label for="middeL_NAME">Middle Name</label>
                  <InputText
                    id="middeL_NAME"
                    type="text"
                    v-model.trim="product.middeL_NAME"
                    required="true"
                    autofocus
                    :class="{ 'p-invalid': submitted && !product.middeL_NAME }"
                  />
                </div>
                <div class="field col-12 md:col-2">
                  <label for="surname">Lastname</label>

                  <InputText
                    id="surname"
                    type="text"
                    v-model.trim="product.surname"
                    required="true"
                    autofocus
                    :class="{ 'p-invalid': submitted && !product.surname }"
                  />
                </div>
                <div class="field col-12 md:col-2">
                  <label for="aadhaR_CARD">Adhar No</label>
                  <InputText
                    id="aadhaR_CARD"
                    type="number"
                    v-model.trim="product.aadhaR_CARD"
                    required="true"
                    autofocus
                    :class="{ 'p-invalid': submitted && !product.aadhaR_CARD }"
                  />
                </div>
                <div class="field col-12 md:col-2">
                  <label for="state">State</label>
                  <InputText
                    id="state"
                    type="text"
                    v-model.trim="product.state"
                    required="true"
                    autofocus
                    :class="{ 'p-invalid': submitted && !product.state }"
                  />
                </div>

                <div class="field col-12 md:col-2">
                  <label for="disrtict">District</label>
                  <InputText
                    id="disrtict"
                    type="text"
                    v-model.trim="product.disrtict"
                    required="true"
                    autofocus
                    :class="{ 'p-invalid': submitted && !product.disrtict }"
                  />
                </div>
                <div class="field col-12 md:col-2">
                  <label for="taluka">Taluka</label>
                  <InputText
                    id="taluka"
                    type="text"
                    v-model.trim="product.taluka"
                    required="true"
                    autofocus
                    :class="{ 'p-invalid': submitted && !product.taluka }"
                  />
                </div>
                <div class="field col-12 md:col-2">
                  <label for="citY_VILLAGE">City/Village</label>
                  <InputText
                    id="citY_VILLAGE"
                    type="text"
                    v-model.trim="product.citY_VILLAGE"
                    required="true"
                    autofocus
                    :class="{ 'p-invalid': submitted && !product.citY_VILLAGE }"
                  />
                </div>
                <div class="field col-12 md:col-2">
                  <label for="pincode">Pincode</label>
                  <InputText
                    id="pincode"
                    type="number"
                    v-model.trim="product.pincode"
                    required="true"
                    autofocus
                    :class="{ 'p-invalid': submitted && !product.pincode }"
                  />
                </div>
                <div class="field col-12 md:col-2">
                  <label for="degree">Degree</label>
                  <InputText
                    id="degree"
                    type="text"
                    v-model.trim="product.degree"
                    required="true"
                    autofocus
                    :class="{ 'p-invalid': submitted && !product.degree }"
                  />
                </div>
              </div>
            </div>
          </div>
          
          
          <div class="col-12">
            <div class="card">
              <div class="p-fluid formgrid grid">
                <div class="field col-12">
                  <label for="address">Address</label>

                  <Textarea
                    id="address"
                    type="text"
                    v-model.trim="product.address"
                    required="true"
                    autofocus
                    :class="{ 'p-invalid': submitted && !product.address }"
                  />
                </div>
                <div class="field col-12 md:col-2">
                  <label for="mobile">Mobile No</label>
                  <InputText
                    id="mobile"
                    type="number"
                    v-model.trim="product.mobile"
                    required="true"
                    autofocus
                    :class="{
                      'p-invalid': submitted && !product.mobile,
                    }"
                  />
                </div>
                <div class="field col-12 md:col-2">
                  <label for="email">Email</label>
                  <InputText
                    id="email"
                    type="text"
                    v-model.trim="product.email"
                    required="true"
                    autofocus
                    :class="{ 'p-invalid': submitted && !product.email }"
                  />
                </div>
                 <div class="field col-12 md:col-2" >
                  <label for="Course">Course</label>
                  <Dropdown
                    id="status"
                    v-model="coursestatus"
                    :options="courseItems"
                    :value="courseItems.value"
                    optionLabel="name"
                    placeholder="Select One"
                     v-on:change="update_sub()"
                    :class="{ 'p-invalid': submitted && !selectstatus }"
                    required="true"
                  ></Dropdown>
                </div>
                <div class="field col-12 md:col-2">
                  <label for="subject">Subject</label>
                  <Dropdown
                    id="status"
                    v-model="subjectstatus"
                    :options="subjectitems"
                    :value="subjectitems.value"
                    optionLabel="name"
                   placeholder="Select One"
                    :class="{ 'p-invalid': submitted && !selectstatus }"
                    required="true"
                  ></Dropdown>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 md:col-6">
            <Button
              v-if="product.teacheR_ID"
              label="UPDATE"
              icon="pi pi-check"
              class="p-button-success mr-1 mb-1"
              @click="Addteacher"
            />
            <Button
              v-else
              label="ADD"
              icon="pi pi-check"
              class="p-button-success mr-1 mb-1"
              @click="Addteacher"
            />
            <Button
              label="Cancel"
              icon="pi pi-times"
              class="p-button-danger mr-1 mb-1"
              @click="visibleFull = false"
            />
          </div>



          
          
          
          
          </Sidebar>
          <Dialog
          v-model:visible="update_pass_Dialog"
          :style="{ width: '450px', width: '450px' }"
          header="Update Password"
          :modal="true"
          class="p-fluid"
        >
          <div class="grid">
            <div class="col-12 md:col-12">
              <!-- <div class="field">
                <label for="enrollmenT_NO">Enrollement No</label>
                <InputText
                  id="enrollmenT_NO"
                  v-model.trim="product.enrollmenT_NO"
                  required="true"
                  autofocus
                  disabled
                  :class="{ 'p-invalid': submitted && !product.enrollmenT_NO }"
                />
              </div> -->
              <div class="field">
                <label for="email">Email</label>
                <InputText
                  id="email"
                  v-model.trim="product.email"
                  required="true"
                  autofocus
                  disabled
                  :class="{ 'p-invalid': submitted && !product.email }"
                />
              </div>
              <div class="field">
                <label for="pass">Password</label>
                <InputText
                  id="pass"
                  v-model.trim="password"
                  required="true"
                  autofocus
                  type="password"
                  :class="{ 'p-invalid': submitted && !password }"
                />
              </div>
              <div class="field">
                <label for="pass">Password</label>
                <InputText
                  id="pass"
                  v-model.trim="conf_password"
                  required="true"
                  autofocus
                  type="password"
                  :class="{ 'p-invalid': submitted && !conf_password }"
                />
              </div>
            </div>
            <div class="col-12 md:col-6">
              <Button
                v-if="product.teacheR_ID"
                label="UPDATE"
                icon="pi pi-check"
                class="p-button-success mr-1 mb-1"
                @click="update_password"
              />
            </div>
            <div class="col-12 md:col-6">
              <Button
                label="Cancel"
                icon="pi pi-times"
                class="p-button-danger mr-1 mb-1"
                @click="update_pass_Dialog = false"
              />
            </div>
          </div>
        </Dialog>

        <Dialog
          v-model:visible="productDialog"
          :style="{ width: '450px', width: '450px' }"
          header="Assistant Teacher Details"
          :modal="true"
          class="p-fluid"
        >
          <div class="grid">
            <div class="col-12 md:col-12">
              <div class="field">
                <!-- <img
            :src="'images/product/shoes.jpg'"
            :alt="product.image"
            width="150"
            class="mt-0 mx-auto mb-5 block shadow-2"
          /> -->
              </div>
              <div class="field">
                <label for="batch">Course</label>
                <InputText
                  id="batch"
                  v-model.trim="product.batch"
                  required="true"
                  autofocus
                  :class="{ 'p-invalid': submitted && !product.batch }"
                />
                
                
              </div>
              <div class="field">
                <label for="name">Name</label>
                <InputText
                  id="name"
                  v-model.trim="product.name"
                  required="true"
                  autofocus
                  :class="{ 'p-invalid': submitted && !product.name }"
                />
                <!-- <small class="p-invalid" v-if="submitted && !product.name"
                  >Name is required.</small
                > -->
                
              </div>
              <!-- <div class="field">
                <label for="description">Description</label>
                <Textarea
                  id="description"
                  v-model.trim="product.description"
                  required="true"
                  autofocus
                  :class="{ 'p-invalid': submitted && !product.description }"
                />
              </div>
              <div class="field">
                <label for="link">Video Link</label>
                <InputText
                  id="link"
                  v-model.trim="product.videolink"
                  required="true"
                  autofocus
                  :class="{ 'p-invalid': submitted && !product.link }"
                />
              </div>
              
              <div class="field">
                <label for="status">Type</label>

                <Dropdown
                  id="state"
                  v-model="selectstatus"
                  :options="dropdownItems"
                  :value="dropdownItems.value"
                  optionLabel="name"
                  placeholder="Select One"
                  :class="{ 'p-invalid': submitted && !selectstatus }"
                  required="true"
                ></Dropdown>
              </div>

              <div class="field">
                <label for="status">Status</label>

                <Dropdown
                  id="state"
                  v-model="selectstatus2"
                  :options="dropdownItems2"
                  :value="dropdownItems2.value"
                  optionLabel="name"
                  placeholder="Select One"
                  :class="{ 'p-invalid': submitted && !selectstatus2 }"
                  required="true"
                ></Dropdown>
              </div> -->


            </div>
            <div class="col-12 md:col-6">
              <!-- <Button
                v-if="product.mjmB_ID"
                label="UPDATE"
                icon="pi pi-check"
                class="p-button-success mr-1 mb-1"
                @click="Addbatch"
               
              />
              <Button
                v-else
                label="ADD"
                icon="pi pi-check"
                class="p-button-success mr-1 mb-1"
                @click="Addbatch"
              /> -->

            </div>
            <div class="col-12 md:col-6">
              <Button
                label="Cancel"
                icon="pi pi-times"
                class="p-button-danger mr-1 mb-1"
                @click="productDialog = false;"
              />
            </div>
          </div>
        </Dialog>

        <Dialog
          v-model:visible="deleteProductDialog"
          :style="{ width: '450px' }"
          header="Confirm"
          :modal="true"
        >
          <div class="flex align-items-center justify-content-center">
            <i
              class="pi pi-exclamation-triangle mr-3"
              style="font-size: 2rem"
            />
            <span v-if="product"
              >Are you sure you want to delete <b>{{ product.firsT_NAME }}{{ product.middeL_NAME }}{{ product.surname }}</b
              >?</span
            >
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteProductDialog = false"
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteTeacher"
            />
          </template>
        </Dialog>

        <Dialog
          v-model:visible="deleteProductsDialog"
          :style="{ width: '450px' }"
          header="Confirm"
          :modal="true"
        >
          <div class="flex align-items-center justify-content-center">
            <i
              class="pi pi-exclamation-triangle mr-3"
              style="font-size: 2rem"
            />
            <span v-if="product"
              >Are you sure you want to delete the selected products?</span
            >
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteProductsDialog = false"
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteSelectedProducts"
            />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import ProductService from "../service/ProductService";
//import FormNewsMaster from "@/components/FormNewsMaster";
//import * as Survey from "survey-vue";

// import axios from "axios";
import apis from "@/apis";
import axios from "axios";

export default {
  data() {
    return {
      page_no:1,
       password: "",
      conf_password: "",
      update_pass_Dialog:false,
      subject:[],
      course_product:[],
       visibleFull: false,
      isLoading:false,
			isLoadingModel:false,
			loading: false,
			fullPage:true,
      file_attachment: "",
      selectstatus: { name: "", value: "" },
      coursestatus: { name: "", value: "" },
      subjectstatus: { name: "", value: "" },
      dropdownItems: [
        { name: "Active", value: "Active" },
        { name: "InActive", value: "InActive" },
        { name: "Deleted", value: "Deleted" },
      ],
      courseItems: [],
      subjectitems:[],
      // dropdownItems: [
      //   { name: "Motivational", value: "Motivational" },
      //   { name: "Interview", value: "Interview" },
      // ],
      // dropdownItems2: [
      //   { name: "Active", value: "Active" },
      //   { name: "InActive", value: "InActive" },
			// 	{ name: "Deleted", value: "Deleted" }
      // ],
      products: null,
      productDialog: false,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      lazyParams: {},
      product: {},
      selectedProducts: null,
      filters: {},
      offset: 1,
      // selectstatus: { name: "Active", value: "Active" },
      // selectstatus2: { name: "Motivational", value: "Motivational" },
      limit: 10,
      columns: [],
      head: [
        "Data Usages",
        "Network Connectivity",
        "Students",
        "Teachers",
        "Student Registration",
        "News Master",
        "Government Panel",
        "Feedback Master",
        "User Queries",
        "Schemes Master",
        "Notice Master",
        "Subject Master",
        "Teacher Schedule Master",
        "Teacher Schedule DE Master",
        "Query Types Master",
        "Sub Query Types Master",
        "Admin Users",
        "Application Roles",
        "Departments",
        "Cast Master",
        "Year Master",
        "District Master",
        "Student Attendance",
        "Student Document",
        "Teachers",
        "Radical ids",
        "Subject Topics",
        "Query Comments",
        "Student Submit Answers",
        "Mock Tests",
        "Mock Test Papers",
        "Mock Test Results",
        "Video",
        "Syllabus",
        "Course"
      ],
      pageno: 1,
      submitted: false,
      totalRecords: 0,
      id: "",
      statuses: [
        { label: "INSTOCK", value: "instock" },
        { label: "LOWSTOCK", value: "lowstock" },
        { label: "OUTOFSTOCK", value: "outofstock" },
      ],
    };
  },
  productService: null,
  async created() {
    this.productService = new ProductService();
    this.initFilters();
    this.get_course_list();
    this.get_subject_list();
  },
  async mounted() {
    // this.productService.getProducts().then(data => this.products = data);
    this.id = this.$route.params.id;

    this.lazyParams = {
      first: 0,
      rows: this.limit,
      sortField: null,
      sortOrder: null,
      filters: this.filters,
    };
    if (this.totalRecords == 0) {
      await this.get_count();
    }
    await this.get_list();
    //alert(this.id);
  },
  watch: {
    async $route() {
      this.products = [];
      this.columns = [];
      this.totalRecords = 0;
      this.id = this.$route.params.id;
      if (this.id) {
        await this.get_count();
        this.get_list();
      }
    },
  },
  methods: {
    update_password() {
      if (!this.product.email) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Update email ",
          life: 3000,
        });
        return false;
      }
      if (!this.password) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter password",
          life: 3000,
        });
        return false;
      }
      if (!this.conf_password) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter Confirm Password",
          life: 3000,
        });
        return false;
      }
      if (this.conf_password != this.password) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Confirm Password Not Match With Password",
          life: 3000,
        });
        return false;
      }

      //api
      var data = {
        username: this.product.email,
        password: this.password,
        type: "teacher",
      };
      this.loading = true;
      var promise = apis.passwordreset(data, this.id);
      promise.then((response) => {
        this.loading = false;
        this.$swal(response.data.message);
        this.update_pass_Dialog = false;
      }).catch((error) => {
            //console.log(error);
            this.isLoadingModel = false;
            this.update_pass_Dialog = false;

            this.$swal.fire(error.response.data);
             this.get_list();
             this.get_count();  
          });
    },
    update_pass(product) {
      this.password = "";
      this.conf_password = "";
      this.product = { ...product };
      
      this.update_pass_Dialog = true;
    },
    get_course_list: function () {
      var data = {
        Limit: 100,
        page_no: this.page_no,
        count: false,
      };
      
      var promise = apis.batchlist(data, this.id);
      promise.then((response) => {
       
        console.log(response);

        var info = response.data;
        if (info) {
          this.courseItems = [];
          for (var i = 0; i < info.length; i++) {
            this.courseItems.push({
              name: info[i].batch,
              value: info[i].mjmB_ID,
            });
          }
        }
      });
    },
    get_subject_list: function () {
      var data = {
        Limit: 100,
        page_no: this.page_no,
        count: false,
      };
      
      var promise = apis.subjectlist(data, this.id);
      promise.then((response) => {
       
        console.log(response);
        this.subject=response.data;
        var info = response.data;
        if (info) {
          this.subjectitems = [];
          for (var i = 0; i < info.length; i++) {
            this.subjectitems.push({
              name: info[i].subject,
              value: info[i].mjmS_ID,
            });
          }
        }
      });
    },

    

    
    handleFileUpload() {
      this.file_attachment = this.$refs.file_attachment.files[0];

      //alert(this.$refs.file_attachment.files[0].name);
    },
    async onPage(event) {
      this.lazyParams = event;
      this.page_no = event.page + 1;
      this.limit = event.rows;
      // console.log(event);
      await this.get_count();
      if (this.totalRecords > 0) {
        this.get_list();
      }
    },
    get_count: function () {
     
      var data = {
        count: true,
        type: "assteacher"
        
      };
      this.loading = true;
      var promise = apis.teacherlist(data, this.id);
      promise.then((response) => {
        this.loading = false;
        console.log(response);
        this.totalRecords = response.data;
        //alert(this.totalRecords);
      });

      console.log(data);
    },
    get_list: function () {
      var data = {
        Limit: this.limit,
        page_no: this.page_no,
        count: false,
        type:"assteacher"
        
        
      };
      this.loading = true;
      var promise = apis.teacherlist(data, this.id);
      promise.then((response) => {
        this.loading = false;
        console.log(response);
        this.products = response.data;
        console.log(this.products[0]);
        if (this.columns.length == 0) {
          if (this.products.length > 0) {
            this.columns = Object.keys(this.products[0]);
          }
        }
      });

      console.log(data);
    },
    formatCurrency(value) {
      if (value)
        return value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      return;
    },
    openNew() {
      this.product = {};
      this.submitted = false;
      this.selectstatus={name:"",value:""};
       this.coursestatus={name:"",value:""};
        this.subjectstatus={name:"",value:""};
      ///this.productDialog = true;
      this.visibleFull = true;
    },
    hideDialog() {
      this.productDialog = false;
      this.submitted = false;
    },
  async Addteacher() {
this.submitted = true;
if (!this.selectstatus.value) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Select Status",
          life: 3000,
        });
        return false;
      }
      if (!this.product.firsT_NAME) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter First Name",
          life: 3000,
        });
        return false;
      }
      if (!this.product.middeL_NAME) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter Middle Name",
          life: 3000,
        });
        return false;
      }
      if (!this.product.surname) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter LastName",
          life: 3000,
        });
        return false;
      }
       if (!this.product.aadhaR_CARD) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter Adhar No",
          life: 3000,
        });
        return false;
      }
      
      if (!this.product.state) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter State",
          life: 3000,
        });
        return false;
      }
      if (!this.product.disrtict) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter District",
          life: 3000,
        });
        return false;
      }
       if (!this.product.taluka) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter Taluka",
          life: 3000,
        });
        return false;
      }
      if (!this.product.citY_VILLAGE) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter city/village",
          life: 3000,
        });
        return false;
      }
      if (!this.product.pincode) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter Pincode",
          life: 3000,
        });
        return false;
      }
      if (!this.product.address) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter address",
          life: 3000,
        });
        return false;
      }
      if (!this.product.mobile) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter mobile",
          life: 3000,
        });
        return false;
      }
      if (!this.product.email) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter email",
          life: 3000,
        });
        return false;
      }
      if (!this.coursestatus.value) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter Course",
          life: 3000,
        });
        return false;
      }
      if (!this.subjectstatus.value) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter Subject",
          life: 3000,
        });
        return false;
      }   
    
      
       //edit
      if (this.product.teacheR_ID) {
        var data = {
          "teacher_id":this.product.teacheR_ID,
    "firsT_NAME": this.product.firsT_NAME,
    "middeL_NAME": this.product.middeL_NAME,
    "surname": this.product.surname,
    "address": this.product.address,
    "state": this.product.state,
    "disrtict": this.product.disrtict,
    "taluka": this.product.taluka,
    "citY_VILLAGE": this.product.citY_VILLAGE,
    "mobile": this.product.mobile,
    "email": this.product.email,
    "password": this.product.password,
    "pincode": this.product.pincode,
    "batch": this.coursestatus.value,
    "batchname": this.coursestatus.name ,
    "aadhaR_CARD": this.product.aadhaR_CARD,
    "status": this.selectstatus.value,
    "createD_ON": this.product.createD_ON,
    "createD_BY": this.product.createD_BY,
    "updateD_ON": this.product.updateD_ON,
    "updateD_BY": localStorage.getItem("full_name"),
    "teacheR_DE_ID": this.product.teacheR_DE_ID,
    "degree": this.product.degree,
    "profilE_PHOTO": this.product.profilE_PHOTO,
    "subject": this.subjectstatus.value,
    "subjectname":this.subjectstatus.name

        };
        this.isLoadingModel = true;
        var promise = apis.editassteachermaster(data);
        promise
          .then((responseapi) => {
            this.$swal(responseapi.data.message);
            this.isLoadingModel = false;
            this.productDialog = false;
            this.visibleFull = false;
            this.get_list();
            this.get_count();
          })
          .catch((error) => {
            //console.log(error);
            this.isLoadingModel = false;
            this.productDialog = false;
            this.visibleFull = false;
            this.$swal.fire(error.response.data);
             this.get_list();
             this.get_count();
          });
      }
      //add-----------------------------------------------------------------------------------------------------------------
      else {
        var data1 = 
        {
         "firsT_NAME": this.product.firsT_NAME,
    "middeL_NAME": this.product.middeL_NAME,
    "surname": this.product.surname,
    "address": this.product.address,
    "state": this.product.state,
    "disrtict": this.product.disrtict,
    "taluka": this.product.taluka,
    "citY_VILLAGE": this.product.citY_VILLAGE,
    "mobile": this.product.mobile,
    "email": this.product.email,
    "password": this.product.password,
    "pincode": this.product.pincode,
    "batch": this.coursestatus.value,
    "batchname": this.coursestatus.name ,
    "aadhaR_CARD": this.product.aadhaR_CARD,
    "status": this.selectstatus.value,
    "createD_ON": this.product.createD_ON,
    "createD_BY": localStorage.getItem("full_name"),
    "updateD_ON": this.product.updateD_ON,
    "updateD_BY": localStorage.getItem("full_name"),
    "teacheR_DE_ID": "1",
    "degree": this.product.degree,
    "profilE_PHOTO": this.product.profilE_PHOTO,
    "subject": this.subjectstatus.value,
    "subjectname":this.subjectstatus.name
        };
        this.isLoadingModel = true;
        var promises = apis.addassteachermaster(data1);
        promises
          .then((responseapi) => {
            
            this.isLoadingModel = false;
            this.productDialog = false;
            this.visibleFull = false;
            this.$swal(responseapi.data.message);
            this.get_list();
            this.get_count();
          })
          .catch((error) => {
            //console.log(error);
            this.isLoadingModel = false;
            this.productDialog = false;
            this.visibleFull = false;
            this.$swal.fire(error.response.data);
            this.get_list();
            this.get_count();
          });
      }

       this.selectstatus={name:"",value:""};
       this.coursestatus={name:"",value:""};
        this.subjectstatus={name:"",value:""};
    },
    saveProduct() {
      this.submitted = true;
      if (this.product.name.trim()) {
        if (this.product.id) {
          this.product.inventoryStatus = this.product.inventoryStatus.value
            ? this.product.inventoryStatus.value
            : this.product.inventoryStatus;
          this.products[this.findIndexById(this.product.id)] = this.product;
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "Product Updated",
            life: 3000,
          });
        } else {
          this.product.id = this.createId();
          this.product.code = this.createId();
          this.product.image = "product-placeholder.svg";
          this.product.inventoryStatus = this.product.inventoryStatus
            ? this.product.inventoryStatus.value
            : "INSTOCK";
          this.products.push(this.product);
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "Product Created",
            life: 3000,
          });
        }
        this.productDialog = false;
        this.product = {};
      }
    },
    update_sub()
    {
      
        this.subjectitems = [];
      for(var i=0 ; i< this.subject.length ; i++)
      {
       // alert(this.subject[i].subject)
        if(this.subject[i].mjmB_ID==this.coursestatus.value)
        {
          
          this.subjectitems.push({
              name: this.subject[i].subject,
              value: this.subject[i].mjmS_ID,
            });
        }
    }
    },
    editProduct(product) {
      this.file_attachment = "";
      this.product = { ...product };
     // this.productDialog = true;
      //status
      this.selectstatus.value = this.product.status;
      this.selectstatus.name = this.product.status;

      //this.coursestatus.value = this.product.gender;
      this.coursestatus.value = this.product.batch;
      this.coursestatus.name = this.product.batchname[0];


      this.subjectitems = [];
      for(var i=0 ; i< this.subject.length ; i++)
      {
        //alert(this.subject[i].subject)
        if(this.subject[i].mjmB_ID==this.product.batch)
        {
          
          this.subjectitems.push({
              name: this.subject[i].subject,
              value: this.subject[i].mjmS_ID,
            });
        }

      }

       this.subjectstatus.value = this.product.subject;
      this.subjectstatus.name = this.product.subjectname[0];

      

      this.visibleFull = true;


    },
    confirmDeleteProduct(product) {
      this.product = product;
      this.deleteProductDialog = true;
    },
    deleteProduct() {
      this.products = this.products.filter((val) => val.id !== this.product.id);
      this.deleteProductDialog = false;
      this.product = {};
      this.$toast.add({
        severity: "success",
        summary: "Successful",
        detail: "Product Deleted",
        life: 3000,
      });
    },

    deleteTeacher() {
      if (this.product.teacheR_ID) {
        var data = {
          teacher_id: this.product.teacheR_ID,
        };
        this.isLoadingModel = true;
        var promise = apis.deleteassteachermaster(data);
        promise.then((responseapi) => {
          this.isLoadingModel = false;
          if (responseapi.data.status == true) {
            
            // this.hideDialog1();
            this.deleteProductDialog = false;
            this.$swal(responseapi.data.message);
            this.get_list();
            this.get_count();
          } else {
            this.deleteProductDialog = false;
            this.$swal(responseapi.data.message);
            this.get_list();
            this.get_count();
          }
        });
      }
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    createId() {
      let id = "";
      var chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteProductsDialog = true;
    },
    deleteSelectedProducts() {
      this.products = this.products.filter(
        (val) => !this.selectedProducts.includes(val)
      );
      this.deleteProductsDialog = false;
      this.selectedProducts = null;
      this.$toast.add({
        severity: "success",
        summary: "Successful",
        detail: "Products Deleted",
        life: 3000,
      });
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
@import "../assets/demo/badges.scss";
</style>
